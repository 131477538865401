import { HttpClientModule } from '@angular/common/http';
import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { AppComponent } from './app.component';
import { CustomEnumControlRenderer } from './custom.enum';
import { DataDisplayComponent } from './data.control';
import { LangComponent } from './lang.control';
import { RequestformComponent } from './requestform/requestform.component';
import { SubmitdialogComponent } from './submitdialog/submitdialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    AppComponent,
    CustomEnumControlRenderer,
    LangComponent,
    DataDisplayComponent,
    RequestformComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDialogModule,
    MatCardModule
  ],
  schemas: [],
//  entryComponents: [CustomAutocompleteControlRenderer, LangComponent, DataDisplayComponent, RequestformComponent],
  entryComponents: [RequestformComponent],


  // Switch the bootstrap list and uncomment ngDoBootStrap to swtich to a custom element
  // Use this line if you want to run ng serve
 // bootstrap: [AppComponent]
  // use this line if you want to compile a custom element and embed somwhere else
   bootstrap: []
})

export class AppModule {
  constructor(readonly injector: Injector) {
    //const el = createCustomElement(RequestformComponent, { injector});
    //customElements.define('jsonform-requestform', el);
  }


  // Use this function if you want to compile a custom element and embed
   ngDoBootstrap() {
     const el = createCustomElement(RequestformComponent, { injector: this.injector});
     customElements.define('app-requestform', el);
   }

}
