import {Input, OnInit} from '@angular/core'
import { JsonFormsControl } from '@jsonforms/angular';
import {
  Actions,
  composeWithUi,
  ControlElement,
  isEnumControl,
  OwnPropsOfControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core';

import {Observable} from 'rxjs';
import {Component} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'jsonforms-custom-enum',
  template: `

        <mat-form-field fxFlex>



            <mat-label>{{ label }}</mat-label>

            <mat-select
                [value]="data"
                (selectionChange)="onSelect($event)">
                <mat-option *ngFor="let option of scopedSchema.enum" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    `
})
export class CustomEnumControlRenderer extends JsonFormsControl implements OnInit {
  @Input() options: string[];
  isLoading: boolean;

  ngOnInit() {
    super.ngOnInit();
    //let r = this.getOwnProps();

    //console.log(r);
    console.log(this.data);
  }
  protected getOwnProps(): OwnPropsOfControl {
    return {
      ...super.getOwnProps(),
    };
  }

  getEventValue = (event: any) => event.target.value;
  onSelect(ev: MatSelectChange) {
    console.log('select Changed',ev);
    const path = composeWithUi(this.uischema as ControlElement, this.path);
    this.jsonFormsService.updateCore(
      Actions.update(path, () => ev.value)
    );
    this.triggerValidation();
  }




}
