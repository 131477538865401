import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-submitdialog',
  templateUrl: './submitdialog.component.html',
  styleUrls: ['./submitdialog.component.css']
})
export class SubmitdialogComponent implements OnInit {

  message: string;
  constructor(public dialogRef: MatDialogRef<SubmitdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {message: string}) {
    this.message=data.message;
   }

  ngOnInit(): void {
  }

}
