    <div *ngIf="dataSchemaObs | async as dataSchema ; else loading">
    <div *ngIf="uiSchemaObs | async as uiSchema ; else loading">
<div [hidden]="token===null">
    <jsonforms
        [(data)]="data"
        [schema]="dataSchema"
        [uischema]="uiSchema"
        [renderers]="renderers"
        [i18n]="i18n"
        [ajv]="ajv"
    ></jsonforms>
</div>




    </div></div>



<ng-template #loading>
    <div> Loading ...</div>
</ng-template>

<div *ngIf="dataSchemaObs | async as dataSchema">
    <div [hidden]="token===null">
        <button mat-button  (click)="onSubmit()" [disabled]="!ajv.validate(dataSchema,data)">{{ this.submitbutton }}</button>
        <button mat-button (click)="onLogout()">Log out</button>
    </div>
<div>
    
<div [hidden]="token!==null" class="mat-body">Please sign in</div>
<div [hidden]="token!==null" #loginRef></div>

<!--
<div id="g_id_onload"
     data-client_id="110259548177-q6358lhnkp8hks174d9du445tv25rc4q.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="popup"
     data-login_uri="https://jirahook.monashhpc-dev.cloud.edu.au/webhook/callback"
     data-auto_prompt="false">
</div>

<div class="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div>
-->




