import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { and, composePaths, createAjv, isEnumControl, isControl, optionIs, rankWith, schemaTypeIs, scopeEndsWith, Tester } from '@jsonforms/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

//import dataAsset from './data';
import { DateAdapter } from '@angular/material/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialResponse } from 'google-one-tap';
import { SubmitdialogComponent } from '../submitdialog/submitdialog.component';
import { delay } from 'rxjs/operators';
import { CustomEnumControlRenderer } from '../custom.enum';


const canteenMenuTester: Tester = and(
  schemaTypeIs('control'),
  scopeEndsWith('item')
);

@Component({
  selector: 'app-requestform',
  templateUrl: './requestform.component.html',
  styleUrls: ['./requestform.component.css']
})
export class RequestformComponent implements OnInit {
  renderers = [
    ...angularMaterialRenderers,
    { tester: rankWith(5, and(isEnumControl, scopeEndsWith('item'))), renderer: CustomEnumControlRenderer}
    
  ];
  //requestType='requestProject';
  @Input() baseurl = '';
  @Input() requesttype = '';
  @Input() clientid = '';
  @Input() fetchdata: boolean;
  @Input() successmessage: string = "Request Submitted"
  @Input() failuremessage: string = "Something went wrong. Please login in and try again"
  @Input() submitbutton: string = "Submit"
  auth2: any;
//  @ViewChild('loginRef', {static: true }) loginElement: ElementRef;

  @ViewChildren('loginRef') loginElement: QueryList<ElementRef>;
  token: CredentialResponse = null;
   
  uiSchemaObs: Observable<any>;
  dataSchemaObs: Observable<any>;
  dataObs: Observable<any>;
  data: any = null;
  interval: any;
  //data = dataAsset;
  i18n = {locale: 'en-AU'}
  dateAdapter;
  ajv = createAjv({
    schemaId: 'id',
    allErrors: true
  });
  constructor(private http: HttpClient, dateAdapter: DateAdapter<Date>,private ref: ChangeDetectorRef,public dialog: MatDialog ) {
    this.ajv.addFormat('time', '^([0-1][0-9]|2[0-3]):[0-5][0-9]$');
    this.dateAdapter = dateAdapter;
    dateAdapter.setLocale(this.i18n.locale);
  }


  addButton(els: QueryList<ElementRef>): void {
    if (!els  || !els.first ) {
      return
    }
    console.log(els.first.nativeElement);
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      parent=els.first.nativeElement,
      //document.getElementById("google-button"),
      { theme: "outline", size: "large", width: "100%" }
    );
  }
  ngAfterViewInit(): void {
    this.loginElement.changes.subscribe((els: QueryList<ElementRef>) => this.addButton(els) )
  }

  ngOnInit(): void {
    let headers = new HttpHeaders();
    let options = { headers: headers, withCredentials: true};
    console.log('ngOnInit of requestform',this.baseurl, this.fetchdata)
    this.dataSchemaObs = this.http.get<any>(this.baseurl+'/schema/'+this.requesttype,options);
    this.uiSchemaObs = this.http.get<any>(this.baseurl+'/uischema/'+this.requesttype,options);


    console.log('request form ngoninit');
    this.interval = setInterval(() => {this.ref.detectChanges(), 1000})
    if (this.fetchdata) {
      this.tryDataFetch()
    }
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: this.clientid, 
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });


    // @ts-ignore
    //google.accounts.id.prompt((notification: PromptMomentNotification) => {});

    try {
      let tmptoken = localStorage.getItem('authz_token');
      if (this.verify_token(tmptoken)) {
        this.token=tmptoken;

        //this.ref.detectChanges();
      } else {
        localStorage.removeItem('authz_token');
        this.token=null;
        //this.ref.detectChanges();
      }
    } catch(e) {
      localStorage.removeItem('authz_token');
      this.token = null;

    }

  }

  verify_token(credential: CredentialResponse) {
    if (credential === undefined) {
      return false
    }
    if (credential === null) {
      return false
    }
    try {
      var decodedToken = null;
      decodedToken = JSON.parse(atob(credential.split('.')[1]));    
      if (decodedToken === null) {
        return false;
      }
      let now = Date.now()/1000
      if (decodedToken.exp > now) {
        return true
      } else {
        return false;
      }
    } catch(e) {
      console.error('caught2',e);
      return false;
    }
  }

  async handleCredentialResponse(response: any) {
    // Here will be your response from Google.
    let google_token = response.credential;
    let headers = new HttpHeaders({
    });
    let options = { headers: headers};
    let body = {'access_token': google_token, 'token_type': ''};

    console.log('posting body',body);
    this.http.post<any>(this.baseurl+'/token', body, options).subscribe((v) => { 
       //this.data = v ; of[0].subscribe(() => {this.ref.detectChanges();})});
        console.log('return from token is',v);
        this.token=v.access_token;
        console.log('token is set to',this.token);
        localStorage.setItem('authz_token', this.token);
        this.tryDataFetch();
        this.ref.detectChanges();
    });
  }

  onLogout() {
    this.token=null;
    this.data = null;
    localStorage.removeItem('authz_token');
  }



  postData(data: any): Observable<any> {
    let access_token: string = <string>this.token;
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    let options = { headers: headers};
    return this.http.post(this.baseurl+'/request/'+this.requesttype,data,options)
  }

  tryDataFetch() {

    console.log('trying data fetch');
    let tmptoken = localStorage.getItem('authz_token');
    console.log('tmptoken',tmptoken);
    if (this.verify_token(tmptoken)) {
      console.log('verified tmptoken');
      this.token=tmptoken;
    
      let access_token: string = <string>this.token;
      let headers = new HttpHeaders({
        'Authorization': `Bearer ${access_token}`
      });
      let options = { headers: headers};
      this.http.get<any>(this.baseurl+'/data/' + this.requesttype, options).subscribe((v) => {  this.data = v ; of([0]).subscribe(() => {this.ref.detectChanges();})});
    }
  }

  handleSubmitError(error: any) {
    this.token=null;
    this.ref.detectChanges();
    try {
      localStorage.removeItem('authz_token');
    } catch {

    }
    let dialogRef = this.dialog.open(SubmitdialogComponent, {
      data: { message: this.failuremessage },
    });

    



  }

  handleSubmitSuccess(data: any) {
    console.log('success');
    this.tryDataFetch();
    let dialogRef = this.dialog.open(SubmitdialogComponent, {
      data: { message: this.successmessage },
    });

  }

  onSubmit(): void {
    this.postData(this.data).subscribe( data => this.handleSubmitSuccess(data), error => this.handleSubmitError(error))
  }





 


}
